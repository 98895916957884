<template>
    <v-app>
        <v-main>
            <v-toolbar height="180px" class="px-8">
                <v-img
                    contain
                    max-height="150px"
                    max-width="150px"
                    src="./assets/logo-vn.svg"
                ></v-img>

                <v-spacer></v-spacer>

                <p class="text-h3 font-weight-bold mb-0">{{date}} {{time}}</p>
            </v-toolbar>
            <v-container fluid class="pa-12">
                <v-row class="fill-height">
                    <v-col cols="12" class="fill-height">
                        <v-carousel
                            cycle
                            height="100%"
                            hide-delimiters
                            interval="30000"
                            :show-arrows="false"
                        >
                            <template v-for="(article, i) in feedItems[0]">
                                <v-carousel-item  :key="i" v-if="article.enclosure && article.enclosure.url">
                                    <v-row class="align-center fill-height">
                                        <v-col cols="6" class="align-self-center pr-7">
                                            <v-img
                                                :aspect-ratio="3/2"
                                                :src="article.enclosure && article.enclosure.url"
                                            ></v-img>
                                        </v-col>
                                        <v-col cols="6" class="pl-7">
                                            <h1 class="text-h3 font-weight-bold mb-5">{{article.title}}</h1>
                                            <p class="text-h4" v-html="decodeHtmlEntities(article.description)"></p>
                                            <template v-if="isolateLink(article.link)">
                                                <p class="overline text-center mt-12 mb-0">mehr lesen</p>
                                                <qrcode-vue
                                                    :value="isolateLink(article.link)" :size="$vuetify.breakpoint.xl ? 300 : 150" level="H"
                                                    class="qrcode mx-auto"
                                                />
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-carousel-item>
                                <!--<v-carousel-item  :key="i" v-if="isolateImage(article.contentcomplete)">
                                    <v-row class="align-center fill-height">
                                        <v-col cols="6" class="align-self-center pr-7">
                                            <v-img
                                                :aspect-ratio="3/2"
                                                :src="isolateImage(article.contentcomplete)"
                                            ></v-img>
                                        </v-col>
                                        <v-col cols="6" class="pl-7">
                                            <h1 class="text-h3 font-weight-bold mb-5">{{article.title}}</h1>
                                            <p class="text-h4" v-html="decodeHtmlEntities(article.description)"></p>
                                            <template v-if="isolateLink(article.link)">
                                                <p class="overline text-center mt-12 mb-0">mehr lesen</p>
                                                <qrcode-vue
                                                    :value="isolateLink(article.link)" :size="$vuetify.breakpoint.xl ? 300 : 150" level="H"
                                                    class="qrcode mx-auto"
                                                />
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-carousel-item>-->
                            </template>
                        </v-carousel>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
const Parser = require('rss-parser');
const parser = new Parser({
    customFields: {
        item: ['description', ['content:encoded', 'contentcomplete']],
    }
});

export default {
    name: 'App',

    components: {
        QrcodeVue
    },

    data: () => ({
        feedItems: [],
        date: [],
        time: []
    }),
    methods: {
        loadFeed()  {
            (async () => {
                await parser.parseURL('https://api-nordstern.vn.at/ressort/startseite/feed', (err, feed) => {
                    if (err) { //Feed unavailable
                        let feedStorage = JSON.parse(localStorage.getItem('feedStorage'));
                        if(feedStorage) { //Show feed from storage
                            this.feedItems.push(feedStorage);
                        } else { //or show error message
                            const feedUnavailable = [{
                                title: "Der Feed ist im Moment leider nicht verfügbar",
                                description: "Bitte versuchen Sie es später erneut.",
                                contentcomplete: "<img src='test'>"
                            }]
                            this.feedItems.push(feedUnavailable);
                        }
                    } else {
                        this.feedItems.push(feed.items);
                        localStorage.setItem('feedStorage', JSON.stringify(feed.items));
                    }
                });
                /*feed.items.forEach(item => {
                    let featuredImage = this.isolateImage(item.contentcomplete);
                    console.log(featuredImage);
                    let decodedDescription = this.decodeHtmlEntities(item.description);
                    console.log(decodedDescription);
                });*/
            })();
        },
        decodeHtmlEntities(description) {
            return description.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        },
        /*isolateImage(itemContent)  {
            let content = document.createElement('div');
            content.innerHTML = itemContent;
            let image = content.querySelector('img');
            if(image)   {
                let imageSrc = 'https://www.vn.at' + image.getAttribute('src');
                return imageSrc;
            } else {
                return;
            }
        },*/
        isolateLink(itemLink)  {
            if(itemLink)   {
                let articleLink = itemLink.replace('api-nordstern.', '').slice(0, -3);
                return articleLink;
            } else {
                return;
            }
        },
        displayDateTime()   {
            // get a new date (locale machine date time)
            var newDate = new Date();
            // get the date
            var DD = ("0" + newDate.getDate()).slice(-2);
            var MM = ("0" + (newDate.getMonth() + 1)).slice(-2);
            var YYYY = newDate.getFullYear();
            var date = DD + '.' + MM + '.' + YYYY;
            this.date = date;
            // get the time
            var hh = ("0" + newDate.getHours()).slice(-2);
            var mm = ("0" + newDate.getMinutes()).slice(-2);
            var time = hh + ':' + mm;
            this.time = time;
        }
    },
    mounted()   {
        this.loadFeed();
        setInterval(
            () => {
                this.loadFeed();
            }, 600000 //Update feed every 10 minutes
        );
        this.displayDateTime();
        setInterval(
            () => {
                this.displayDateTime();
            }, 1000 //Update time every second
        );
    }
};
</script>
    